<template>
    <div v-loading="isLoading">
        <div class="text-left d-flex align-items-center">
            <div class="pl-2 flex-fill">
                <div><h4><strong>{{title}}</strong></h4></div>
            </div>
            <div class="pr-2">
                <el-button type="primary" round size="small" @click="getData">
                    <i class="el-icon-refresh"></i> Refresh
                </el-button>
            </div>
        </div>
        <highcharts class="mt-4 mr-1" :options="avgAmountOnRechargeTime.options"></highcharts>
    </div>
</template>

<script>
    import chartDataApi from '@/api/common/chartData';
    var numeral = require('numeral');
    import { debounce } from 'vue-debounce';
    import { Chart } from 'highcharts-vue';
    import {
        areaSplineChart,
        colorSun, colorSat, colorNormal
    } from "@/constant/dashboardChartOptions";

    const special = ['zeroth', 'first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth', 'tenth', 'eleventh', 'twelfth', 'thirteenth', 'fourteenth', 'fifteenth', 'sixteenth', 'seventeenth', 'eighteenth', 'nineteenth'];
    const deca = ['twent', 'thirt', 'fort', 'fift', 'sixt', 'sevent', 'eight', 'ninet'];

    export default {
        components: {
            highcharts: Chart,
        },
        props: {
            title: {
                type: String,
                default: "",
            },
            options: {
                type: Object,
                default() {
                    return { minAmount: 1500, maxAmount: 3000, maxRechargeTimeGet: 7 };
                }
            }
        },
        watch: {
            options: {
                handler: function (newValue) {
                    this.getData();
                },
                deep: true
            }
        },
        data() {
            return {
                debounceFnc: debounce(() => {

                }, 300),
                isLoading: false,
                listData: [],
                avgAmountOnRechargeTime: {
                    options: Object.assign(JSON.parse(JSON.stringify(areaSplineChart)), {
                        chart: {
                            height: 350,
                            type: 'areaspline'
                        },
                        legend: { enabled: false },
                        tooltip: {
                            shared: true
                        },
                        plotOptions: {
                            areaspline: {
                                dataLabels: {
                                    enabled: true
                                },
                                fillOpacity: 0.5
                            }
                        },
                    }),
                }
            };
        },
        mounted() {
            this.getData();
            this.debounceFnc = debounce(() => {
                this.getData();
            }, 300);
        },
        methods: {
            stringifyNumber(n) {
                let resultText = '';
                if (n < 20) resultText = special[n];
                else {
                    if (n % 10 === 0) resultText = deca[Math.floor(n / 10) - 2] + 'ieth';
                    else resultText = deca[Math.floor(n / 10) - 2] + 'y-' + special[n % 10];
                }
                return resultText.charAt(0).toUpperCase() + resultText.slice(1);
            },
            getData() {
                this.isLoading = true;
                let requestData = { ...this.options };
                chartDataApi.avgAmountOnRechargeTime(requestData).then(response => {
                    if (response.data && response.data.result === 0 && response.data.data !== null) {
                        this.listData = response.data.data;
                        this.avgAmountOnRechargeTime.options.xAxis.categories = [];
                        this.avgAmountOnRechargeTime.options.xAxis.categories = response.data.data.map(x => `${this.stringifyNumber(x.RechargeTime)} recharged`);
                        this.avgAmountOnRechargeTime.options.series = [{
                            name: "Avg recharged",
                            dataLabels: {
                                enabled: true
                            },
                            tooltip: {
                                valuePrefix: '$'
                            },
                            data: response.data.data.map(x => x.AvgAmount)
                        }];
                        this.isLoading = false;
                    }
                    else {
                        if (response.data && response.data.message !== null && response.data.message !== '') {
                            console.error(response.data.message || this.$lang.common.error);
                        }
                        this.isLoading = false;
                    }
                }).catch(error => {
                    console.error(error);
                    this.isLoading = false;
                });
            },
        }
    }
</script>